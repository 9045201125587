import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { api_url } from '../config';
import { useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import api from '../utils/api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const UpdateCardDetailsForm = ({ toggle }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { token, id, email } = useSelector((state) => state.auth);
    const [clientSecret, setClientSecret] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSetupIntent = async () => {
            try {
                const { data } = await api.get(`${api_url}/v1/user/${id}/setup-intent`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });
                setClientSecret(data.client_secret);
            } catch (err) {
                setError("Failed to initialize payment setup. Please try again.");
            }
        };
        fetchSetupIntent();
    }, [id, token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const cardElement = elements.getElement(CardElement);
            const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: { email },
                },
            });

            if (error) {
                setError(error.message);
            } else if (setupIntent.status === 'succeeded') {
                try {
                    await axios.post(
                        `${api_url}/v1/user/${id}/payment-method`,
                        { ...setupIntent},
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setSuccess(true);
                    setTimeout(()=>{
                        toggle()
                    },2500)
                } catch (err) {
                    setError("Failed to update payment method");
                }
            }
        } catch (err) {
            setError("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
        
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
            <div className='flex justify-between'>
                <h2 className="text-2xl font-semibold mb-4">Update Card Details</h2>
                <MdClose
                    className='cursor-pointer hover:scale-105'
                    onClick={() => { toggle() }}
                    size={22} />
            </div>

            <div className="p-2 mb-4 border border-gray-300 rounded">
                <CardElement />
            </div>

            <button
                type="submit"
                disabled={!stripe || !elements || !clientSecret || loading}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm w-full"
            >
                {loading ? "Updating..." : "Update Card"}
            </button>

            {error && <div className="mt-4 text-red-800 bg-red-200 rounded-lg flex mx-auto">
                <p className='text-center mx-auto'>{error}</p>
            </div>}
            {success && <div className="mt-4 bg-green-300 text-green-800 rounded-lg flex mx-auto">
                <p className='text-center mx-auto'>Card details updated successfully!</p>
            </div>}
        </form>
    );
};

const UpdateCardDetails = ({ toggle }) => (
    <Elements stripe={stripePromise}>
        <UpdateCardDetailsForm toggle={toggle} />
    </Elements>
);

export default UpdateCardDetails;
